import React from 'react';

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '250px' }}>
            <h1 className="text-center text-6xl font-bold">404 - Not Found</h1>
            <p className="text-center text-3xl mt-4">The page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;