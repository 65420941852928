import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import video from "../images/showcase.mp4";
import Video from "./Video";

const Services = () => {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === "en";

  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="max-h-6xl mx-auto flex flex-col lg:flex-row items-center">
          {/* Video Section */}
          <div className="lg:w-1/2 w-full flex justify-center m-4" style={{border: '4px solid grey'}}>
            <Video video={video} muted={true} autoPlay={true} loop={true} controls={false} />
          </div>

          {/* Content Section */}
          <div className="lg:w-1/2 w-full flex flex-col gap-4 px-4">
            {/* First Block */}
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-start">
                <div className="custom-blue mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="72"
                    viewBox="0 0 24 24"
                    className="fill-current"
                  >
                    <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                    <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                  </svg>
                </div>
                <h3 className="text-2xl font-bold mx-4">{isEnglish ? "Why Trust Us" : "למה לסמוך עלינו"}</h3>
              </div>
              <p className="text-lg text-gray-600 font-semibold">
                {isEnglish
                  ? "Trust us with your procurement needs, as we manage hundreds of millions of dollars in procurement annually, ensuring optimal efficiency and cost-effectiveness. We collaborate with the largest and most reputable vendors globally, giving you access to top-tier products and services. Our strong industry recommendations and proven track record highlight our expertise."
                  : "אנו מנהלים מאות מיליוני דולרים ברכש מדי שנה, ומבטיחים יעילות מרבית וחסכוניות. אנו משתפים פעולה עם הספקים הגדולים והמכובדים ביותר בעולם, ומעניקים לכם גישה למוצרים ולשירותים מהשורה הראשונה. המלצות החזקות שלנו בתעשייה ורקורד מוכח מדגישים את המומחיות שלנו."}
              </p>
            </div>

            {/* Second Block */}
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-start">
                <div className="custom-blue mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="72"
                    height="72"
                    viewBox="0 0 24 24"
                    className="fill-current"
                  >
                    <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                  </svg>
                </div>
                <h3 className="text-2xl font-bold">{isEnglish ? "Effortless Integration" : "אינטגרציה קלה"}</h3>
              </div>
              <p className="text-lg text-gray-600 font-semibold">
                {isEnglish
                  ? "No integration is required, and no training is necessary for your employees or suppliers. Evolinq seamlessly integrates into your existing workflows, allowing you to streamline your purchase order processes without any disruption. Our user-friendly platform is designed to adapt effortlessly to your current systems, ensuring a smooth transition and immediate efficiency gains."
                  : "אין צורך באינטגרציה ואין צורך בהכשרה לעובדים או לספקים שלכם. Evolinq משתלב בצורה חלקה בזרימות העבודה הקיימות שלכם, ומאפשר לכם לייעל את תהליכי הזמנות הרכש ללא כל הפרעה. הפלטפורמה הידידותית שלנו למשתמש מותאמת בקלות למערכות הקיימות שלכם, ומבטיחה מעבר חלק ורווחי יעילות מיידיים."}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
