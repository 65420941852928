import React, { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { LanguageContext } from '../context/LanguageContext';

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === 'en';

  return (
    <>
      <footer className="w-full">
        <div className="max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-6">
          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-black-200 font-semibold py-1">
                {isEnglish ? (
                  <>
                    Copyright &copy; {new Date().getFullYear()}{" "}
                    <HashLink to="#" className="hover:text-gray-900">
                      Evolinq
                    </HashLink>. All rights reserved.
                  </>
                ) : (
                  <>
                    זכויות יוצרים &copy; {new Date().getFullYear()}{" "}
                    <HashLink to="#" className="hover:text-gray-900">
                      אבולינק בע״מ
                    </HashLink>. כל הזכויות שמורות.
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
