import React, { useContext } from "react";
import InfiniteScrollBanner from "../InfiniteScrollBanner";
import { LanguageContext } from "../../context/LanguageContext";

import aws from './assets/aws.jpg'
import mamram from './assets/mamram.svg'
import driveTLV from './assets/driveTLV.png'
import a8200 from './assets/8200.jpg'

const Partners = () => {
    const isEnglish = useContext(LanguageContext);
    const logos = [aws, mamram, driveTLV, a8200]
    return ( <>
    <InfiniteScrollBanner items={logos} title={isEnglish ? "Proud To Be Alumni Of" : "גאים להיות בוגרי האקסלרטורים"} pauseOnHover={false} isRTL={!isEnglish} itemHeight={102} itemWidth={240} gap={48}/>
</>
    )
}

export default Partners;