import React from "react";
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Evolinq</title>
        <meta name="description" content="Welcome to Evolinq, your AI-driven solution for supply chain management." />
        <meta name="keywords" content="Evolinq, AI, Supply Chain, Management" />
        <meta property="og:title" content="Evolinq Platform" />
        <meta property="og:description" content="Welcome to Evolinq, your AI-driven solution for supply chain management." />
        <meta property="og:url" content="https://www.evolinq.io" />
        <meta property="og:image" content="https://www.evolinq.io/images/og-image.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="full-screen-container">
        <iframe
          src="https://evolinq.retool.com/app/dashboard"
          title="Evolinq Dashboard"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="full-screen-iframe"
        ></iframe>
      </div>
    </>
  );
};

export default Dashboard;
