import React, { useEffect, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LanguageProvider, LanguageContext } from "./context/LanguageContext";
// All pages
import Home from "./pages/Home";
import Careers from "./pages/Careers";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import DemoProduct from "./pages/Demo";
import Dashboard from "./pages/Dashboard";
import DisplayEmail from './pages/DisplayEmail';
import SupplierStatusTable from './pages/SupplierStatusTable';
import NotFound from './pages/NotFound';
import AboutUs from './pages/AboutUs';

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";

const Layout = ({ children }) => {
  const hideFooter = ["/dashboard", "/dashboard/", "/display-email", "/display-email/", "/supplier-status-table", "/supplier-status-table/"].includes(window.location.pathname);

  const { language } = useContext(LanguageContext);
  useEffect(() => {
    document.body.className = language === 'he' ? 'rtl' : '';
  }, [language]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

const App = () => {
  useDocTitle("Evolinq");

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  return (
    <Router>
      <LanguageProvider>
        <ScrollToTop>
          <Layout>
            <Routes>
              {/* Routes with he language prefix */}
              <Route path="/he" element={<Home />} />
              <Route path="/he/home" element={<Home />} />
              <Route path="/he/careers" element={<Careers />} />
              <Route path="/he/blog" element={<Blog />} />
              <Route path="/he/demo" element={<DemoProduct />} />
              <Route path="/he/blog/:slug" element={<BlogPost />} />
              <Route path="/he/about-us" element={<AboutUs />} />
              
              {/* Routes with en language prefix */}
              <Route path="/en" element={<Home />} />
              <Route path="/en/home" element={<Home />} />
              <Route path="/en/careers" element={<Careers />} />
              <Route path="/en/blog" element={<Blog />} />
              <Route path="/en/demo" element={<DemoProduct />} />
              <Route path="/en/blog/:slug" element={<BlogPost />} />
              <Route path="/en/about-us" element={<AboutUs />} />
              {/* Handle root paths and redirect to a default language */}
              <Route path="/" element={<Navigate to="/en/" />} />
              <Route path="/home" element={<Navigate to="/en/home" />} />
              <Route path="/careers" element={<Navigate to="/en/careers" />} />
              <Route path="/demo" element={<Navigate to="/en/demo" />} />
              <Route path="/blog" element={<Navigate to="/en/blog" />} />
              <Route path="/blog/:slug" element={<Navigate to="/en/blog/:slug" />} />
              <Route path="/about-us" element={<Navigate to="/en/about-us" />} />
              {/* Special Routes without a language support */}
              <Route path="/display-email" element={<DisplayEmail />} />
              <Route path="/supplier-status-table" element={<SupplierStatusTable />} />
              <Route path="/dashboard" element={<Dashboard />} />

              {/* Catch-all for 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </ScrollToTop>
      </LanguageProvider>
    </Router>
  );
};

export default App;
