import React from 'react';
import WhatsApp from '@mui/icons-material/WhatsApp';

const WhatsAppIcon = (number) => {

  const handleClick = () => {
    window.open(`https://wa.me/${number}`);
  };

  return (
    <WhatsApp size={36} color="green" onClick={handleClick} />
  );
};

export default WhatsAppIcon;