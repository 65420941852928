import React from 'react';

const Profile = ({ name, role, img }) => {
    return (
      <div className="flex flex-col items-center">
        <div className="w-48 h-48 rounded-full overflow-hidden">
          <img
            src={img}
            alt={role}
            width={192}
            height={192}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="text-center">
          <h2 className="text-xl font-semibold">{name}</h2>
          <p className="text-gray-600">{role}</p>
        </div>
      </div>
    );
  };
  
  export default Profile;
  