import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Initialize language based on the current URL
  const getInitialLanguage = () => {
    const pathLang = window.location.pathname.split('/')[1];
    return pathLang === 'he' || pathLang === 'en' ? pathLang : 'en';
  };

  const [language, setLanguage] = useState(getInitialLanguage);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'he' : 'en';
    setLanguage(newLanguage);

    const pathWithoutLang = window.location.pathname.split('/').slice(2).join('/');
    const newPath = `/${newLanguage}/${pathWithoutLang}`;
    window.history.pushState(null, '', newPath);
  };

  useEffect(() => {
    const pathLang = window.location.pathname.split('/')[1];
    if (pathLang !== language) {
      setLanguage(pathLang);
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
