import React, { useContext } from "react";
import img from "../images/value-graph.png";
import { LanguageContext } from "../context/LanguageContext";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Intro = () => {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === "en";
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
      <div className="flex flex-col-reverse lg:flex-row py-8 justify-between items-center gap-8" data-aos="fade-up">
        {/* Image Section */}
        <div className="lg:w-1/2 flex justify-center">
          <img className="rounded-t w-full max-h-[500px] object-contain" src={img} alt="Value Graph" />
        </div>

        {/* Accordion Section */}
        <div className="md:w-2/3 max-w-md">
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography>{isEnglish ? "Generate Insights" : "הפקת תובנות"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {isEnglish
                  ? "Our app consolidates all your procurement data—whether from unstructured emails or managed ERP systems—into a single, unified platform. Gain complete visibility into your procurement operations and leverage AI to uncover meaningful insights that help reduce supplier friction and optimize processes."
                  : "האפליקציה שלנו מרכזת את כל נתוני הרכש שלך — בין אם מדובר במיילים לא מובנים או מערכות ERP מנוהלות — למקום אחד מאוחד. קבלו שקיפות מלאה על תהליכי הרכש שלכם והשתמשו בבינה מלאכותית כדי להפיק תובנות משמעותיות שיפחיתו חיכוכים עם ספקים וישפרו תהליכים."}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography>{isEnglish ? "Automate Processes" : "אוטומציה של תהליכים"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {isEnglish
                  ? "Transform your procurement workflow by automating up to 80% of manual tasks. Communicate with hundreds of suppliers in just one click and establish a fully automated procurement process, saving time and increasing efficiency."
                  : "שדרגו את תהליכי הרכש שלכם על ידי אוטומציה של עד 80% מהמשימות הידניות. תקשרו עם מאות ספקים בלחיצה אחת והקימו תהליך רכש אוטומטי לחלוטין שיחסוך זמן וישפר את היעילות."}{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography>{isEnglish ? "Mitigate Risks" : "צמצום סיכונים"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {isEnglish
                  ? "Stay ahead of delays or price fluctuations on the vendor side with real-time alerts. Address issues just in time (JIT) to minimize errors and costly mistakes, ensuring smoother operations and maximizing savings."
                  : "הישארו בשליטה על עיכובים או שינויים במחירים מצד הספקים באמצעות התראות בזמן אמת. טפלו בבעיות בדיוק בזמן (JIT) כדי למזער טעויות וחוסרים ולמקסם את החיסכון הכספי."}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Intro;
