import React, { useContext } from "react";
import InfiniteScrollBanner from "../InfiniteScrollBanner";
import { LanguageContext } from "../../context/LanguageContext";

import logo1 from "./assets/logo.png";
import logo2 from "./assets/logo 2.png";
import logo3 from "./assets/logo 3.jpg";
import logo4 from "./assets/logo.jpg";


const CustomersBar = () => {
  const isEnglish = useContext(LanguageContext);
  const logos = [logo1, logo2, logo3, logo4];
  return <InfiniteScrollBanner items={logos} title={isEnglish ? "Evolinq is trusted by the best" : "הטובים ביותר סומכים על אוולינק"} pauseOnHover={false} isRTL={!isEnglish} itemHeight={102} itemWidth={240} gap={48}/>;
};

export default CustomersBar;
