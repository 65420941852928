import React from "react";
import noam from './assets/noam.jpg'
import or from './assets/or.jpg'
import Profile from "../Profile";

const TeamMembers = () => {
  return (
    <div className="flex flex-row justify-around gap-12 items-center">
        <Profile className="mt-8" name="Noam Shaukri" role="CEO" img={noam} />
        <Profile name="Or Feldman" role="CTO" img={or} />
    </div>
  );
};

export default TeamMembers;
