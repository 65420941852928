import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Video from "../components/Video";
import { LanguageContext } from '../context/LanguageContext';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import video from '../images/marketing_video.mp4';

const Slogan = () => {
    const { language } = useContext(LanguageContext);
    const isEnglish = language === 'en';

    return (
        <>
            <div className="hero pt-8 lg:pt-10" id="slogan">
                <div className="m-auto overflow-hidden mx-4 mt-6 lg:pt-12 md:p-12 h-full flex flex-col lg:flex-row items-center lg:items-center" data-aos="zoom-in">
                    
                    {/* Video Section with Overlay Text */}
                    <div className="w-full flex justify-center lg:w-2/3 order-1 lg:order-2 lg:ml-20 mb-12 relative" data-aos="fade-up" data-aos-delay="700">
                        
                        <Video video={video} muted={false} autoPlay={false} loop={false} controls={true} />
                    </div>

                    {/* Text Section */}
                    <div id='slogan' className="flex flex-col justify-center text-center lg:text-left lg:w-2/3 order-2 lg:order-1 lg:pl-8">
                        <h1 className="mb-5 md:text-7xl text-4xl font-extrabold">
                            {isEnglish ? (
                                <>
                                    AI-Driven<br />Supply Chain<br />Management.
                                </>
                            ) : (
                                <>
                                    ניהול שרשרת<br />אספקה בעזרת<br />בינה מלאכותית.
                                </>
                            )}
                        </h1>

                        {/* Subtitle */}
                        <p className="text-lg text-gray-600 mb-4">
                            {isEnglish ? "Effortlessly manage your suppliers with cutting-edge AI." : "נהלו את הספקים שלכם בקלות בעזרת בינה מלאכותית."}
                        </p>

                        {/* Unique Selling Points with Icons */}
                        <div className="md:text-2xl text-xl font-semibold tracking-tight mb-5 flex flex-col space-y-3" id="slogan2">
                            
                                <>
                                    <div className="flex items-center">
                                        <AutorenewIcon className="text-blue-500 mr-2 " />
                                        {isEnglish ? "Automating your entire procurement" : "אוטומציה מלאה של תהליך הרכש."}
                                        <br />
                                        {isEnglish ? "processes with all of your suppliers." : "התממשקות עם כל הספקים."}
                                    </div>
                                    <div className="flex items-center">
                                    <IntegrationInstructionsIcon className="text-blue-500 mr-2 color: pink" />
                                    {isEnglish ? "Zero-Integrations required." : "אפס שינויים נדרשים לאינגרטציה מלאה."}
                                    </div>
                                    <div className="flex items-center">
                                        <CheckCircleIcon className="text-blue-500 mr-2" />
                                        {isEnglish ? "Nothing is required on the supplier's side." : "אין צורך בשום דבר מצד הספקים."}
                                    </div>
                                </>
                        </div>

                        {/* Call to Action Button */}
                        <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                            <Link to="/demo" className="text-white bg-[#166AEA] hover:bg-[#145BCF] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                {isEnglish ? 'Book a Demo' : 'קבעו הדגמה'}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Slogan;
