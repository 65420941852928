import React from 'react';

const InfiniteScrollBanner = ({
  items = [],
  title = '',
  speed = 20,
  itemWidth = 160,
  itemHeight = 96,
  gap = 32,
  className = '',
  isRTL = false,
  pauseOnHover = true,
}) => {
  const numberOfCopies = 3;

  const ItemGroup = () => (
    <div className="flex min-w-max">
      {items.map((item, index) => (
        <div
          key={`${item.id || index}`}
          className="flex items-center justify-center bg-white rounded-lg shadow-md flex-shrink-0"
          style={{ 
            width: itemWidth, 
            height: itemHeight, 
            marginLeft: gap, 
            marginRight: gap 
          }}
        >
            <img 
              src={item} 
              alt={`Item ${index + 1}`}
              className="object-contain p-4 w-full h-full"
            />
        </div>
      ))}
    </div>
  );

  const totalWidth = (itemWidth + (gap * 2)) * items.length;
  
  // Direction-specific animation
  const startTransform = isRTL ? `-${totalWidth}px` : '0';
  const endTransform = isRTL ? '0' : `-${totalWidth}px`;

  return (
    <div className={`w-full bg-gray-50 py-12 overflow-hidden ${className}`}>
      <style>
        {`
          @keyframes scroll {
            0% { transform: translateX(${startTransform}); }
            100% { transform: translateX(${endTransform}); }
          }
          .scroll-container {
            display: flex;
            position: relative;
            width: max-content;
            ${isRTL ? 'flex-direction: row-reverse;' : ''}
          }
          .scroll-container > div {
            animation: scroll ${speed}s linear infinite;
            animation-fill-mode: forwards;
            min-width: max-content;
          }
          ${pauseOnHover && ':hover .scroll-container > div { animation-play-state: paused; }'}
          .scroll-container,
          .scroll-container > div {
            margin: 0;
            padding: 0;
            line-height: normal;
          }
        `}
      </style>
      
      {title && (
        <h2 className="text-2xl font-bold text-center mb-8 text-gray-800">
          {title}
        </h2>
      )}
      
      <div 
        className="relative flex overflow-hidden pause-on-hover"
        style={{ 
          direction: isRTL ? 'rtl' : 'ltr',
        }}
      >
        <div className="scroll-container">
          {[...Array(numberOfCopies)].map((_, index) => (
            <ItemGroup key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollBanner;