import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../components/Navbar/NavBar";
import { LanguageContext } from "../context/LanguageContext";
import TeamMembers from "../components/TeamMembers/TeamMembers";
import Partners from "../components/Partners/Partners";

export default function AboutUs() {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === "en";

  return (
    <>
      <Helmet>
        <title>{isEnglish ? "Evolinq | About" : "אבולינק | אודות"}</title>
        <meta
          name="description"
          content={
            isEnglish
              ? "Request a free demo of Evolinq's AI-driven supply chain management platform and see how our solutions can optimize your factory's procurement process."
              : "בקשו הדגמה חינמית של הפלטפורמה לניהול שרשרת האספקה המונעת בבינה מלאכותית של אבולינק, וראו כיצד הפתרונות שלנו יכולים לייעל את תהליך הרכש במפעל שלכם."
          }
        />
        <meta name="keywords" content="Evolinq, AI, Supply Chain, Demo, Management, Free Demo" />
        <link rel="canonical" href="https://www.evolinq.io/demo" />
        <meta property="og:title" content={isEnglish ? "Request a Demo - Evolinq" : "בקשו הדגמה - אבולינק"} />
        <meta
          property="og:description"
          content={
            isEnglish
              ? "Discover how Evolinq's AI-driven platform can transform your supply chain management. Request a free demo today!"
              : "גלו כיצד הפלטפורמה המונעת בבינה מלאכותית של אבולינק יכולה לשנות את ניהול שרשרת האספקה שלכם. בקשו הדגמה חינמית עוד היום!"
          }
        />
        <meta property="og:url" content="https://www.evolinq.io/demo" />
        <meta property="og:image" content="https://www.evolinq.io/images/og-image-demo.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <NavBar />
      </div>
      <div className="container mt-12 py-12 mx-auto px-6 lg:px-32">
        <h1 className="text-4xl font-bold text-center mb-8">{isEnglish ? "About Evolinq" : "אודות אבולינק"}</h1>

        <div className="mb-12 text-justify max-w-3xl mx-auto space-y-6">
          <p className="text-lg">
            {isEnglish
              ? "Evolinq is driven by a dedicated team of AI and cybersecurity professionals focused on reshaping tactical procurement and supply chain management through advanced AI solutions. Our expertise stems from deep industry knowledge and a commitment to delivering secure, impactful, and transformative results for businesses worldwide."
              : "אבולינק מורכבת מצוות מסור של מומחי בינה מלאכותית ואבטחת סייבר, אשר מתמקדים בעולם הרכש וניהול שרשרת האספקה באמצעות פתרונות בינה מלאכותית מתקדמים. המומחיות שלנו נובעת מידע מעמיק בתעשייה וממחויבות לספק תוצאות מאובטחות, משמעותיות ומשנות מציאות לעסקים ברחבי העולם."}
          </p>
          <p className="text-lg">
            {isEnglish
              ? "We are proud to have earned the trust and backing of prestigious accelerators, including the Mamram Space Accelerator, the 8200 Accelerator, and DriveTLV. These leading programs recognized our potential early on, offering their invaluable support and resources to fuel our mission. Our solutions have captured the confidence of key industry leaders, investors, and a network that believes in our vision."
              : "אנו גאים בכך שזכינו לאמון ולתמיכה של מאיצים יוקרתיים, כולל המאיץ של ממרם, מאיץ 8200 ו-DriveTLV. תוכניות מובילות אלו זיהו את הפוטנציאל שלנו מוקדם וסיפקו למשימה שלנו משאבים ותמיכה רחבה. הפתרונות שלנו זכו לאמון של מנהיגי תעשייה מרכזיים ונתמכים על ידי רשת שמאמינה בחזון שלנו."}
          </p>
          <p className="text-lg">
            {isEnglish
              ? "At Evolinq, we leverage our cutting-edge AI capabilities to simplify procurement processes, provide unparalleled visibility, and minimize operational workload—empowering businesses to optimize their supply chains and achieve greater efficiency with complete peace of mind."
              : "באבולינק אנו מנצלים את יכולות הבינה המלאכותית המתקדמות שלנו כדי לפשט תהליכי רכש, לספק הבנת  מידע חסרת תקדים ולהפחית עומסי עבודה תפעוליים - ובכך לאפשר לעסקים לייעל את שרשרת האספקה שלהם ולהשיג יעילות רבה יותר בשקט נפשי מלא."}
          </p>
        </div>

        <div className="flex justify-center items-center space-x-12 mt-12">
          <TeamMembers />
        </div>
      </div>
      <div className="mb-12">
        <Partners />
      </div>
    </>
  );
}
