import React from 'react';

const Video = ({ video, muted, autoPlay, loop, controls }) => {
    return (
        <>
            <div className="m-auto max-w-6xl h-5/6" id='video' >
                <video className="rounded-t" src={video} loop={loop} autoPlay={autoPlay} muted={muted} controls={controls} />
            </div>
        </>
    );
}

export default Video;
