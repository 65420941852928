import React from 'react';
import video from '../images/marketing_video.mp4';

const Video = () => {
    return (
        <>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='video' >
                <video className="rounded-t" src={video} controls />
            </div>
        </>
    );
}

export default Video;
