import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NavBar from '../components/Navbar/NavBar';
import { LanguageContext } from '../context/LanguageContext';

const importBlogPostContent = async (slug, language) => {
    try {
        const content = await import(`../blogPosts/${slug}/${language}.html`);
        return content.default;
    } catch (error) {
        console.error("Error importing blog post content:", error);
        return null;
    }
};

const importBlogPostImage = async (slug) => {
    try {
        const image = await import(`../blogPosts/${slug}/${slug}_image.jpg`);
        return image.default;
    } catch (error) {
        console.error("Error importing blog post image:", error);
        return null;
    }
};

const blogPosts = [
    {
        slug: "the-cornerstone-of-successful-manufacturing-companies",
        title: {
            en: 'The Cormerstone of Successful Manufacturing Companies',
            he: 'תיאום אספקה: אבן היסוד של חברות ייצור מצליחות'
        },
        uploadTime: {
            en: 'December 14, 2023',
            he: '14 בדצמבר, 2023'
        },
    },
    {
        slug: "coordinating-supplies-in-the-import-world",
        title: {
            en: 'Coordinating Supplies in the Import World',
            he: 'תיאום אספקות בעולמות הייבוא: אתגרים, סכנות, ופתרונות לניהול חכם'
        },
        uploadTime: {
            en: 'February 22, 2024',
            he: '22 בפברואר, 2024'
        },
    },
    {
        slug: "supply-management-in-times-of-war-and-epidemics",
        title: {
            en: 'Supply Management in Times of War and Epidemics',
            he: 'ניהול אספקות בזמני מלחמה ומגפות: אתגרים, היערכות ופתרונות מתקדמים'
        },
        uploadTime: {
            en: 'August 15, 2024',
            he: '15 באוגוסט, 2024'
        },
    },
    {
        slug: "direct-vs-indirect-procurement",
        title: {
            en: 'Direct vs Indirect Procurement: Revolutionizing Supply Chain Management with AI',
            he: 'רכש ישיר מול רכש עקיף: מהפכת ניהול שרשרת האספקה עם בינה מלאכותית'
        },
        uploadTime: {
            en: 'November 10, 2024',
            he: '10 בנובמבר, 2024'
          },
    }
];

const BlogPost = () => {
    const { slug } = useParams();
    const { language } = useContext(LanguageContext);
    const isEnglish = language === 'en';
    const post = blogPosts.find(p => p.slug === slug);

    const [content, setContent] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const updateDemoButtons = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const demoBtns = doc.querySelectorAll('.demo-btn');
        demoBtns.forEach(btn => {
            btn.textContent = isEnglish ? 'Book a Demo' : 'קבעו הדגמה';
            btn.setAttribute('href', isEnglish ? '/en/demo#demo' : '/he/demo#demo');
        });

        const updatedHtml = doc.documentElement.outerHTML;
        return updatedHtml;
    };

    useEffect(() => {
        const loadContent = async () => {
            try {
                const contentHtml = await importBlogPostContent(slug, language);
                const image = await importBlogPostImage(slug);

                if (contentHtml) {
                    const updatedHtml = updateDemoButtons(contentHtml);
                    setContent(updatedHtml);
                }
                if (image) {
                    setImageUrl(image);
                }
            } catch (error) {
                console.error("Error loading the content or image", error);
            }
        };
        loadContent();
    }, [slug, language]);

    if (!post) {
        return <div>{isEnglish ? 'Post not found' : 'הפוסט לא נמצא'}</div>;
    }

    return (
        <div>
            <Helmet title={post.title[language]} />
            <NavBar />
            <div
            id="careers"
            className="py-12 bg-cover bg-center min-h-screen"
            >
            <section data-aos="zoom-in-down">
                <div style={{backgroundColor: "black", backgroundImage: "linear-gradient(black , #1565C0)", paddingTop: "70px", paddingBottom: "70px", marginTop: "50px", marginBottom: "50px"}}>
                <div className="my-4 py-4">
                <h1 className="my-2 text-center text-6xl text-white font-bold">
                    {isEnglish ? "Blog" : "בלוג"}
                </h1>
                <h3 className="text-center text-3xl text-white mb-10 mt-10">
                    {isEnglish
                    ? "Articles on the Latest Supply Chain Trends"
                    : "מאמרים על טרנדים בשרשרת האספקה"}
                </h3>
                </div>
                </div>

                <div className="container mx-auto pb-12 px-4 md:px-10 lg:px-80">
                    {imageUrl && <img src={imageUrl} alt={post.title[language]} className="w-full h-64 object-cover rounded-lg mb-8" />}
                    <h1 className="text-4xl font-bold mb-4">{post.title[language]}</h1>
                    <p className="text-gray-500 text-sm mb-4">{post.uploadTime[language]}</p>
                    <div className="text-lg" dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
            </section>
            </div>
        </div>
    );
};

export default BlogPost;
